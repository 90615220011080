import { useEffect, useState } from 'react';
import Router, { useRouter } from 'next/router';
import { getCookie, removeCookie } from 'utils/cookie';
import { EnterFlowFeature } from '../constants/EnterFlowFeature';
import { getOrderDetails } from 'utils/api/order';
import { OrderType } from 'components/payments/PayPalAdyenCheckout';
import { getInvitedByDetails } from 'utils/api/bonus';
import { ReferralBonusScheme } from 'utils/ReferralBonusScheme';
import { fetchOngoingFunnelTrackingVariantIfAvailable } from '../cookies/fetchOngoingFunnelTrackingVariantIfAvailable';
import { fetchOngoingWixVariantIfAvailable } from '../cookies/fetchOngoingWixVariantIfAvailable';
import { fetchSplitTestVariantIfAvailable } from '../cookies/fetchSplitTestVariantIfAvailable';
import { User } from 'types/User';
import { navigateToConfirmation } from 'utils/navigation';

export const useInitData = (
  currentUser: User | null,
  preselectedEntryMethod: string
) => {
  const jwt = getCookie('jwt', null);
  const router = useRouter();
  const [loadingSplitTestVariant, setLoadingSplitTestVariant] = useState(true); // So we don't flash the wrong design to the user upon page load.
  const [ongoingFunnelTrackingVariant, setOngoingFunnelTrackingVariant] =
    useState(undefined);
  const [wixTracking, setWixTracking] = useState(null);
  const [splitTestVariant, setSplitTestVariant] = useState(undefined);
  const [isReferralFlow, setIsReferralFlow] = useState(false);
  const [loadingReferralBonusScheme, setLoadingReferralBonusScheme] =
    useState(true);
  const [hiddenFeatures, setHiddenFeatures] = useState<string[]>([]);

  const hideFeatures = () => {
    setHiddenFeatures([
      EnterFlowFeature.MULTIPLE_ENTRIES,
      EnterFlowFeature.MULTIPLE_DRAWS,
      EnterFlowFeature.BOOST_OR_TOP_UP,
    ]);
  };

  useEffect(() => {
    if (router.query?.orderId && jwt) {
      (async () => {
        const orderId = (router.query.orderId as string) ?? null;
        if (!orderId) return;
        const orderDetails = await getOrderDetails(
          jwt,
          orderId,
          OrderType.TICKET_ORDER
        );
        if (orderDetails?.order?.state === 'COMPLETE') {
          removeCookie('referral_id');
          navigateToConfirmation(router, orderDetails.id, true);
        }
      })();
    }
  }, [router.query?.orderId]);

  useEffect(() => {
    const fetchReferralDetails = async () => {
      const referralId = router.query?.ri || getCookie('referral_id');

      if (!referralId) {
        setLoadingReferralBonusScheme(false);
        return;
      }

      const invitedByDetails = await getInvitedByDetails(referralId);
      if (!invitedByDetails || invitedByDetails.successful === false) {
        setLoadingReferralBonusScheme(false);
        return;
      }

      const { referralBonusScheme } = invitedByDetails;
      setIsReferralFlow(
        [
          ReferralBonusScheme.Give12Get12,
          ReferralBonusScheme.Give6Get6,
          ReferralBonusScheme.LocaliteAndDiscount,
        ].includes(referralBonusScheme)
      );
      setLoadingReferralBonusScheme(false);

      if (
        [
          ReferralBonusScheme.Give12Get12,
          ReferralBonusScheme.Give6Get6,
        ].includes(referralBonusScheme)
      ) {
        hideFeatures();
      }
    };

    if (router.isReady && loadingReferralBonusScheme) {
      fetchReferralDetails();
    }
  }, [router.isReady, router.query?.ri]);

  useEffect(() => {
    const fetchOngoingVariants = async () => {
      fetchOngoingFunnelTrackingVariantIfAvailable(
        preselectedEntryMethod,
        setOngoingFunnelTrackingVariant
      );
      fetchOngoingWixVariantIfAvailable(preselectedEntryMethod, setWixTracking);
      fetchSplitTestVariantIfAvailable(
        preselectedEntryMethod,
        setSplitTestVariant,
        setLoadingSplitTestVariant,
        router,
        currentUser
      );
    };

    fetchOngoingVariants();

    const promoCode = getCookie('promo_code');
    if (promoCode === 'OURHOMEANDAWAY') {
      hideFeatures();
    }
  }, []);

  return {
    loadingSplitTestVariant,
    ongoingFunnelTrackingVariant,
    wixTracking,
    splitTestVariant,
    isReferralFlow,
    loadingReferralBonusScheme,
    hiddenFeatures,
  };
};
