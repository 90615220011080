import { FunnelFlags, SplitTest, trackWixEvent } from 'utils/SplitTesting';

export const fetchOngoingWixVariantIfAvailable = async (
  preselectedEntryMethod,
  setWixTracking
) => {
  const variant = localStorage.getItem(`${SplitTest.OngoingWix}_VARIANT`);
  if (variant) {
    await trackWixEvent(FunnelFlags.VisitedEnter, preselectedEntryMethod);
    setWixTracking(true);
  }
};
