import {
  FunnelFlags,
  SplitTest,
  trackFunnelEventIfHaveNotAlready,
} from 'utils/SplitTesting';

export const fetchOngoingFunnelTrackingVariantIfAvailable = async (
  preselectedEntryMethod,
  setOngoingFunnelTrackingVariant
) => {
  const variant = localStorage.getItem(
    `${SplitTest.OngoingFunnelTracking}_VARIANT`
  );
  if (variant) {
    await trackFunnelEventIfHaveNotAlready(
      SplitTest.OngoingFunnelTracking,
      FunnelFlags.VisitedEnter,
      variant,
      preselectedEntryMethod
    );
    setOngoingFunnelTrackingVariant(variant);
  }
};
