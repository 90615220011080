import {
  ENTER_FLOW_SPLIT_TEST_TRACKING,
  ENTER_PAGE_SPLIT_TEST,
  EXCLUDE_FREE_ENTER_PAGE,
  FunnelFlags,
  getVariantForSplitTest,
  trackFunnelEventIfHaveNotAlready,
} from 'utils/SplitTesting';
import { EntryMethod } from '../EntryMethod';

export const fetchSplitTestVariantIfAvailable = async (
  preselectedEntryMethod,
  setSplitTestVariant,
  setLoadingSplitTestVariant,
  router,
  currentUser
) => {
  let variant = null;

  if (ENTER_PAGE_SPLIT_TEST) {
    // We are split testing a change on the enter page.
    if (
      !EXCLUDE_FREE_ENTER_PAGE ||
      preselectedEntryMethod !== EntryMethod.FreeSubscription
    ) {
      if (typeof ENTER_PAGE_SPLIT_TEST === 'object') {
        if (preselectedEntryMethod === EntryMethod.LiteSubscription) {
          // @ts-ignore
          const liteOneOffTest = ENTER_PAGE_SPLIT_TEST.find((test) =>
            test.includes('LITE')
          );
          variant = await getVariantForSplitTest(currentUser, liteOneOffTest);
        }

        if (preselectedEntryMethod === EntryMethod.PremiumSubscription) {
          // @ts-ignore
          const premiumOneOffTest = ENTER_PAGE_SPLIT_TEST.find(
            (test) => !test.includes('LITE')
          );

          variant = await getVariantForSplitTest(
            currentUser,
            premiumOneOffTest
          );
        }
      } else {
        if (preselectedEntryMethod === EntryMethod.PremiumSubscription) {
          variant = await getVariantForSplitTest(
            currentUser,
            ENTER_PAGE_SPLIT_TEST
          );
        }
      }
    }
  }

  if (ENTER_FLOW_SPLIT_TEST_TRACKING) {
    // We are split testing something (not necessarily on the enter page) and we want to see how it
    // impacts progression through the enter flow.
    // NOTE: This will override the ENTER_PAGE_SPLIT_TEST variant. In general, if ENTER_PAGE_SPLIT_TEST
    // is set to a SplitTest then ENTER_FLOW_SPLIT_TEST_TRACKING should be set to the same SplitTest
    // as we probably want to track whether the changing the enter page has impacted progression
    // through the whole enter flow.
    // UPDATE:
    // ENTER_FLOW_SPLIT_TEST_TRACKING is now an array for oneoff split tests for Lite and Premium
    if (typeof ENTER_FLOW_SPLIT_TEST_TRACKING === 'object') {
      let test;
      // @ts-ignore
      for (let i = 0; i < ENTER_FLOW_SPLIT_TEST_TRACKING.length; i++) {
        // @ts-ignore
        const splitTest = ENTER_FLOW_SPLIT_TEST_TRACKING[i];
        if (localStorage.getItem(`${splitTest}_VARIANT`)) {
          if (
            preselectedEntryMethod === EntryMethod.PremiumSubscription &&
            !splitTest.includes('LITE')
          ) {
            test = splitTest;
            break; // stop the loop
          }
          if (
            preselectedEntryMethod === EntryMethod.LiteSubscription &&
            splitTest.includes('LITE')
          ) {
            test = splitTest;
            break; // stop the loop
          }
        }
      }
      variant = localStorage.getItem(`${test}_VARIANT`);
    } else {
      if (preselectedEntryMethod === EntryMethod.PremiumSubscription) {
        variant = localStorage.getItem(
          `${ENTER_FLOW_SPLIT_TEST_TRACKING}_VARIANT`
        );
      }
    }
    if (variant) {
      await trackFunnelEventIfHaveNotAlready(
        ENTER_FLOW_SPLIT_TEST_TRACKING,
        FunnelFlags.VisitedEnter,
        variant,
        preselectedEntryMethod
      );
    }
  }
  const overrideVariant = router?.query?.overrideVariant;
  setSplitTestVariant(overrideVariant ?? variant);
  setLoadingSplitTestVariant(false);
};
